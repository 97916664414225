:root {
  --background-color: #ffffff;
  --text-color: #000000;
  --sec-text-color: #000000;
  --main-color: #e2e2e2;
  --sec-color: #cbcbcb;
  --blue-color: #54a7ff;
  --blue-color-hover: #88c2ff;
}

.dark-mode {
  --background-color: #0b1217;
  --text-color: #ffffff;
  --sec-text-color: #a8a8a8;
  --main-color: #171d22;
  --sec-color: #2a353e;
  --blue-color: #54a7ff;
  --blue-color-hover: #88c2ff;
}
