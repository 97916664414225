@import "../../variables.css";

.tokens-status {
  height: 180px;
  background-color: var(--main-color);
  transition: background-color 0.3s ease;
  border-radius: 0px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  padding-left: 15%;
  padding-right: 15%;
}

.token-address-name {
  height: 22px;
  border-radius: 15px 15px 0px 0px;
  width: 100%;
  background-color: var(--sec-color);
  color: var(--text-color);
  font-weight: bold;
  align-items: center;
}

.token-note {
  height: 22px;
  border-radius: 0px 0px 15px 15px;
  width: 100%;
  background-color: var(--sec-color);
  font-size: 12px;
  color: var(--sec-text-color);
  align-items: center;
  height: auto;
  margin-bottom: 10px;
}

.token-lable {
  width: 100%;
  color: var(--text-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.transaction-lable {
  width: 100%;
  color: var(--text-color);
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  align-items: center;
}

.transaction-counts {
  width: 100%;
  color: var(--text-color);
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 40px;
  align-items: center;
}

.separator {
  width: 100%;
  height: 1px;
  color: var(--sec-text-color);
  margin: 10px 0;
}

.txn {
  font-size: 30px;
  font-weight: bold;
}

.txn-sell {
  font-size: 15px;
  color: red;
  font-weight: bold;
}

.txn-buy {
  font-size: 15px;
  color: green;
  font-weight: bold;
}

@media only screen and (max-width: 1250px) {
  .token-status {
    height: 180px;
    background-color: var(--sec-color);
    border-radius: 0px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    padding-left: 10%;
    padding-right: 10%;
  }
}
