.error-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.error-message button {
  width: 80px !important;
}

.error {
  color: #fff;
  font-weight: bold;
  font-size: 15px;
}
