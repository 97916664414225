@import "../../variables.css";
@import "~@fortawesome/fontawesome-free/css/all.css";

.App-header {
  background-color: var(--main-color);
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  height: 30px;
  transition: background-color 0.3s ease;
}

.logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--text-color);
  text-decoration: none;
}

.logo img {
  width: 25px;
  height: 100%;
  margin-right: 10px;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.right-wrapper {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.theme-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #3396ff;
  border-color: transparent;
  height: 100%;
  border-radius: 10px;
  width: 35px;
}


.theme-toggle:hover {
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #54a7ff;
  border-color: transparent;
  height: 100%;
  border-radius: 10px;
}

.theme-toggle-icon {
  font-size: 16px;
}

.theme-toggle .theme-toggle-icon {
  font-size: 15px;
  color: #fff;
}

.theme-toggle.dark .theme-toggle-icon {
   color: #fff;
}

.theme-toggle:focus {
  outline: none;
}

@media only screen and (max-width: 768px) {
  .logo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: var(--text-color);
    font-size: 10px;
  }

  .logo img {
    width: 25px;
    height: 100%;
    margin-right: 10px;
  }
}
