.announcement-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, #54a7ff, #90c5ff, #0a81ff);
  background-size: 200% auto;
  animation: gradientAnimation 3s linear infinite;
  height: 35px;
  padding: 0 10px;
}

.announcement-text {
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  flex: 1;
  text-decoration: none;
}

.dismiss-button {
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 25px;
  transition: box-shadow 0.3s ease;
}

.dismiss-button:hover {
  filter: drop-shadow(0 0 1px rgba(255, 255, 255, 0.823));
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% center;
  }
  50% {
    background-position: 100% center;
  }
  100% {
    background-position: 0% center;
  }
}

@media only screen and (max-width: 768px) {
  .announcement-text {
    font-size: 12px;
  }
}
